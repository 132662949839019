import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { getI18n } from 'react-i18next';

import { schema } from '../../../../../state/schema';
import {
  loadResourcesAdmin,
  deleteResourceAdmin,
  resourceAdminShowPreviewDetail,
  patchResourceAdmin,
  loadResourceIdsAdmin,
  deleteResourcesAdmin,
  downloadResourceCSVAdmin,
} from '../../../../../state/modules/resourcesAdmin/actions';
import ResourcesAdminList from '../components/ResourcesAdminList';
import storage from '../../../../../lib/Storage';
import { resourceNoteShowListAdmin } from '../../../../../state/modules/resourceNotes/actions';

const get_care_guide_name = (x) => {
  if (x?.amount_assigned_care_guides > 1) {
    return `${x.amount_assigned_care_guides} members`;
  } else if (x?.care_guide) {
    if (x?.care_guide?.first_name && x?.care_guide?.last_name) {
      return `${x?.care_guide?.first_name} ${x?.care_guide?.last_name}`;
    }
  } else if (x?.partnership) {
    return x?.partnership?.name;
  }
  return getI18n().t('resource_universal');
};

const get_profile_name = (x) => {
  if (x?.profile) {
    return `${x?.profile?.first_name} ${x?.profile?.last_name} `;
  }
  return getI18n().t('resource_profile_empty');
};

const mapStateToProps = (state, ownProps) => {
  const partnershipActiveId = storage.get('local', 'partnershipId');

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.resourcesAdmin.getIn([
    'pages',
    partnershipActiveId,
    order,
    limit,
    page,
  ]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['resourcesAdmin', x]),
                schema.resourceAdmin,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              care_guide_name: get_care_guide_name(x),
              profile_name: get_profile_name(x),
              ...x,
            };
          })
      : [],
    isLoading: state.resourcesAdmin.get('isLoading'),
    ids: state.resourcesAdmin.get('ids').toArray(),
    isLoadingIds: state.resourcesAdmin.get('isLoadingIds'),
    total: state.resourcesAdmin.get('total'),
    partnershipActiveId,
    partnershipName: storage.get('local', 'partnershipData')?.name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnershipId, page, limit, order, filter, cb) => {
      dispatch(
        loadResourcesAdmin(partnershipId, page, limit, order, filter, cb)
      );
    },
    remove: (partnership_id, id, cb) => {
      dispatch(deleteResourceAdmin(partnership_id, id, cb));
    },
    removes: (partnership_id, payload, cb) => {
      dispatch(deleteResourcesAdmin(partnership_id, payload, cb));
    },
    showDetail: (resourceId) => {
      dispatch(resourceAdminShowPreviewDetail(resourceId));
    },
    patch: (partnershipActiveId, id, data, cb) => {
      dispatch(patchResourceAdmin(partnershipActiveId, id, data, cb));
    },
    loadIds: (id, filter, cb) => {
      dispatch(loadResourceIdsAdmin(id, filter, cb));
    },
    showList: (resourceId) => {
      dispatch(resourceNoteShowListAdmin(resourceId));
    },
    download: (partnershipId, partnershipName, resourceId, cb) => {
      dispatch(
        downloadResourceCSVAdmin(partnershipId, partnershipName, resourceId, cb)
      );
    },
  };
};

const ResourcesAdminListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcesAdminList);

export default ResourcesAdminListContainer;
