import React from 'react';
import { Button, Typography, Card, List, Row, Col, Avatar } from 'antd';
import { Translation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';
import Format from '../../../../../lib/Format';
import UserAvatar from '../../../elements/components/UserAvatar';

const { Title, Text } = Typography;

const DashboardAdminUpcomingEvents = ({ list, ...props }) => {
  return (
    <Translation>
      {(t) => (
        <Card bordered={false} className="event-upcoming-card">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Title level={4}>
              {t('dashboard_admin_upcoming_events_title')}
            </Title>
            <Button type="primary" icon={<EyeOutlined />}>
              {t('dashboard_admin_upcoming_events_btn_view')}
            </Button>
          </div>

          <List
            itemLayout="horizontal"
            size="small"
            bordered={false}
            dataSource={list}
            renderItem={(item) => (
              <div className={`event-upcoming-item`}>
                <Row
                  align="top"
                  justify="space-between"
                  style={{ height: '20px' }}
                >
                  <Col>
                    <Title level={5}>{item.title}</Title>
                  </Col>
                  <Col>
                    {item.care_guide_id === null ? (
                      <UserAvatar profile={item.profile} size={24} />
                    ) : (
                      <div className="event-content">
                        <Avatar.Group
                          maxCount={3}
                          maxStyle={{
                            color: '#f56a00',
                            backgroundColor: '#fde3cf',
                          }}
                        >
                          {item.participants
                            ? item.participants.map((x, i) => (
                                <UserAvatar
                                  key={i}
                                  profile={x.profile}
                                  size={24}
                                />
                              ))
                            : null}
                        </Avatar.Group>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row align="top">
                  <Col>
                    <Text style={{ color: '#676767' }}>
                      {
                        item.is_all_day
                          ? Format.date(item.start_at, 'ddd, MMM D') // all day event
                          : Format.date(item.start_at, 'YYYY-MM-DD') ===
                            Format.date(item.end_at, 'YYYY-MM-DD')
                          ? Format.date(item.start_at, 'ddd, MMM D h:mm a') // event occurs on a single day
                          : Format.date(item.start_at, 'YYYY-MM') ===
                            Format.date(item.end_at, 'YYYY-MM')
                          ? Format.date(item.start_at, 'MMM D') +
                            '-' +
                            Format.date(item.end_at, 'D') // event occurs over multiple days, same month
                          : Format.date(item.start_at, 'MMM D') +
                            '-' +
                            Format.date(item.end_at, 'MMM D') // event occurs over multiple days, different month
                      }
                    </Text>
                  </Col>
                </Row>
              </div>
            )}
          />
        </Card>
      )}
    </Translation>
  );
};

export default DashboardAdminUpcomingEvents;
