import DashboardAdminRecommendedUtilityFormModal from '../components/DashboardAdminRecommendedUtilityFormModal';
import { schema } from '../../../../../state/schema';
import { List } from 'immutable';
import {
  recommendedUtilityFormDestroy,
  recommendedUtilityHideForm,
  loadRecommendedUtilities,
  recommendedUtilityShowForm,
  createRecommendedUtility,
  updateRecommendedUtility,
  destroyDocument,
  uploadDocument,
} from '../../../../../state/modules/recommendedUtilities/actions';

import { createPresignedUrlAWS } from '../../../../../state/actions';

import { denormalize } from 'normalizr';
import { connect } from 'react-redux';
import storage from '../../../../../lib/Storage';

const inputs = List(['title', 'doc-file', 'website']);

const mapStateToProps = (state, ownProps) => {
  const partnershipId = storage.get('local', 'partnershipId');

  const errors = {};
  for (const val of inputs.values()) {
    if (state.recommendedUtilities.getIn(['form', 'errors', val])) {
      errors[val] = state.recommendedUtilities.getIn(['form', 'errors', val]);
    }
  }

  const recommendedUtilityId = state.recommendedUtilities.get('id');

  const recommendedUtility = recommendedUtilityId
    ? denormalize(
        state.entities.getIn(['recommendedUtilities', recommendedUtilityId]),
        schema.recommendedUtility,
        state.entities.toJS()
      )
    : null;

  const data = {
    title: recommendedUtility ? recommendedUtility.title : '',
    file_name_bucket: recommendedUtility
      ? recommendedUtility.file_name_bucket
      : '',
    file_name: recommendedUtility ? recommendedUtility.file_name : '',
    file_directory: recommendedUtility ? recommendedUtility.file_directory : '',
    file_bucket: recommendedUtility ? recommendedUtility.file_bucket : '',
    file_extension: recommendedUtility ? recommendedUtility.file_extension : '',
    size_bytes: recommendedUtility ? recommendedUtility.size_bytes : '',
    website: recommendedUtility ? recommendedUtility.website : '',
  };

  return {
    visible: state.recommendedUtilities.get('isFormVisible'),
    isSubmitting:
      state.recommendedUtilities.getIn(['form', 'isSubmitting']) ||
      state.recommendedUtilities.get('isDocumentUploading') ||
      state.recommendedUtilities.get('isDocumentArchiving') ||
      state.recommendedUtilities.get('isDocumentDestroying') ||
      state.session.get('isAwsAction'),
    success: state.recommendedUtilities.getIn(['form', 'success']),
    recommendedUtilityId: recommendedUtilityId,
    recommendedUtility: recommendedUtility,
    data: data,
    errors: errors,
    partnershipId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    formDestroy: (formState) => {
      dispatch(recommendedUtilityFormDestroy(formState));
    },
    hideForm: () => {
      dispatch(recommendedUtilityHideForm());
    },
    create: (partnershipId, data, cb) => {
      dispatch(createRecommendedUtility(partnershipId, data, cb));
    },
    update: (partnershipId, id, data, cb) => {
      dispatch(updateRecommendedUtility(partnershipId, id, data, cb));
    },
    load: (partnershipId, page, limit, order, cb) => {
      dispatch(loadRecommendedUtilities(partnershipId, page, limit, order, cb));
    },
    getUploadUrl: (path, fileType, cb) => {
      dispatch(createPresignedUrlAWS(path, fileType, cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadDocument(path, file, cb));
    },

    destroy: (path, cb) => {
      dispatch(destroyDocument(path, cb));
    },
    showForm: (recommendedUtilityId) => {
      dispatch(recommendedUtilityShowForm(recommendedUtilityId));
    },
  };
};

const DashboardAdminRecommendedUtilityFormModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardAdminRecommendedUtilityFormModal);

export default DashboardAdminRecommendedUtilityFormModalContainer;
