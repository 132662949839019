import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Logger from '../../../lib/Logger';
import Format from '../../../lib/Format';

// ACTION TYPES

export const TYPES = {
  RESOURCE_ADMIN_LIST_REQUEST: 'RESOURCE_ADMIN_LIST_REQUEST',
  RESOURCE_ADMIN_LIST_SUCCESS: 'RESOURCE_ADMIN_LIST_SUCCESS',
  RESOURCE_ADMIN_LIST_FAILURE: 'RESOURCE_ADMIN_LIST_FAILURE',
  RESOURCE_ADMIN_LIST_PINNED_REQUEST: 'RESOURCE_ADMIN_LIST_PINNED_REQUEST',
  RESOURCE_ADMIN_LIST_PINNED_SUCCESS: 'RESOURCE_ADMIN_LIST_PINNED_SUCCESS',
  RESOURCE_ADMIN_LIST_PINNED_FAILURE: 'RESOURCE_ADMIN_LIST_PINNED_FAILURE',
  RESOURCE_ADMIN_LIST_DASHBOARD_REQUEST:
    'RESOURCE_ADMIN_LIST_DASHBOARD_REQUEST',
  RESOURCE_ADMIN_LIST_DASHBOARD_SUCCESS:
    'RESOURCE_ADMIN_LIST_DASHBOARD_SUCCESS',
  RESOURCE_ADMIN_LIST_DASHBOARD_FAILURE:
    'RESOURCE_ADMIN_LIST_DASHBOARD_FAILURE',
  RESOURCE_ADMIN_READ_REQUEST: 'RESOURCE_ADMIN_READ_REQUEST',
  RESOURCE_ADMIN_READ_SUCCESS: 'RESOURCE_ADMIN_READ_SUCCESS',
  RESOURCE_ADMIN_READ_FAILURE: 'RESOURCE_ADMIN_READ_FAILURE',
  RESOURCE_ADMIN_UPDATE_REQUEST: 'RESOURCE_ADMIN_UPDATE_REQUEST',
  RESOURCE_ADMIN_UPDATE_SUCCESS: 'RESOURCE_ADMIN_UPDATE_SUCCESS',
  RESOURCE_ADMIN_UPDATE_FAILURE: 'RESOURCE_ADMIN_UPDATE_FAILURE',
  RESOURCE_ADMIN_CREATE_REQUEST: 'RESOURCE_ADMIN_CREATE_REQUEST',
  RESOURCE_ADMIN_CREATE_SUCCESS: 'RESOURCE_ADMIN_CREATE_SUCCESS',
  RESOURCE_ADMIN_CREATE_FAILURE: 'RESOURCE_ADMIN_CREATE_FAILURE',
  RESOURCE_ADMIN_DELETE_REQUEST: 'RESOURCE_ADMIN_DELETE_REQUEST',
  RESOURCE_ADMIN_DELETE_SUCCESS: 'RESOURCE_ADMIN_DELETE_SUCCESS',
  RESOURCE_ADMIN_DELETE_FAILURE: 'RESOURCE_ADMIN_DELETE_FAILURE',
  RESOURCE_ADMIN_DELETES_REQUEST: 'RESOURCE_ADMIN_DELETES_REQUEST',
  RESOURCE_ADMIN_DELETES_SUCCESS: 'RESOURCE_ADMIN_DELETES_SUCCESS',
  RESOURCE_ADMIN_DELETES_FAILURE: 'RESOURCE_ADMIN_DELETES_FAILURE',
  RESOURCE_ADMIN_FORM_DESTROY: 'RESOURCE_ADMIN_FORM_DESTROY',
  RESOURCE_ADMIN_PATCH_REQUEST: 'RESOURCE_ADMIN_PATCH_REQUEST',
  RESOURCE_ADMIN_PATCH_SUCCESS: 'RESOURCE_ADMIN_PATCH_SUCCESS',
  RESOURCE_ADMIN_PATCH_FAILURE: 'RESOURCE_ADMIN_PATCH_FAILURE',
  RESOURCES_ADMIN_PATCH_REQUEST: 'RESOURCES_ADMIN_PATCH_REQUEST',
  RESOURCES_ADMIN_PATCH_SUCCESS: 'RESOURCES_ADMIN_PATCH_SUCCESS',
  RESOURCES_ADMIN_PATCH_FAILURE: 'RESOURCES_ADMIN_PATCH_FAILURE',
  RESOURCE_ADMIN_SHOW_PREVIEW_DETAIL: 'RESOURCE_ADMIN_SHOW_PREVIEW_DETAIL',
  RESOURCE_ADMIN_HIDE_PREVIEW_DETAIL: 'RESOURCE_ADMIN_HIDE_PREVIEW_DETAIL',
  RESOURCE_ADMIN_IDS_REQUEST: 'RESOURCE_ADMIN_IDS_REQUEST',
  RESOURCE_ADMIN_IDS_SUCCESS: 'RESOURCE_ADMIN_IDS_SUCCESS',
  RESOURCE_ADMIN_IDS_FAILURE: 'RESOURCE_ADMIN_IDS_FAILURE',
  RESOURCE_ADMIN_CSV_DOWNLOAD_REQUEST: 'RESOURCE_ADMIN_CSV_DOWNLOAD_REQUEST',
  RESOURCE_ADMIN_CSV_DOWNLOAD_SUCCESS: 'RESOURCE_ADMIN_CSV_DOWNLOAD_SUCCESS',
  RESOURCE_ADMIN_CSV_DOWNLOAD_FAILURE: 'RESOURCE_ADMIN_CSV_DOWNLOAD_FAILURE',
};

// ACTION CREATORS

export function resourceAdminListRequest(
  partnershipId,
  page,
  limit,
  order,
  filter
) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminListRequest(${partnershipId}, ${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.RESOURCE_ADMIN_LIST_REQUEST,
    partnershipId: partnershipId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function resourceAdminListSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminListSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.RESOURCE_ADMIN_LIST_SUCCESS,
    partnershipId: partnershipId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function resourceAdminListFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminListFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.RESOURCE_ADMIN_LIST_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function resourceAdminListPinnedRequest(
  partnershipId,
  page,
  limit,
  order,
  filter
) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminListPinnedRequest(${partnershipId}, ${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.RESOURCE_ADMIN_LIST_PINNED_REQUEST,
    partnershipId: partnershipId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function resourceAdminListPinnedSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminListPinnedSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.RESOURCE_ADMIN_LIST_PINNED_SUCCESS,
    partnershipId: partnershipId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function resourceAdminListPinnedFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminListPinnedFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.RESOURCE_ADMIN_LIST_PINNED_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function resourceAdminListDashboardRequest(
  partnershipId,
  page,
  limit,
  order,
  filter
) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminListDashboardRequest(${partnershipId}, ${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.RESOURCE_ADMIN_LIST_DASHBOARD_REQUEST,
    partnershipId: partnershipId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function resourceAdminListDashboardSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminListDashboardSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.RESOURCE_ADMIN_LIST_DASHBOARD_SUCCESS,
    partnershipId: partnershipId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function resourceAdminListDashboardFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminListDashboardFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.RESOURCE_ADMIN_LIST_DASHBOARD_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function resourceAdminReadRequest(id) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminReadRequest(${id})`
  );
  return {
    type: TYPES.RESOURCE_ADMIN_READ_REQUEST,
    id: id,
  };
}

export function resourceAdminReadSuccess(data) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminReadSuccess(%j)`,
    data
  );
  return {
    type: TYPES.RESOURCE_ADMIN_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function resourceAdminReadFailure(error) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminReadFailure(%j)`,
    error
  );
  return {
    type: TYPES.RESOURCE_ADMIN_READ_FAILURE,
    error: error,
  };
}

export function resourceAdminUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: TYPES.RESOURCE_ADMIN_UPDATE_REQUEST,
  };
}

export function resourceAdminUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminUpdateSuccess(%j)`,
    data
  );
  return {
    type: TYPES.RESOURCE_ADMIN_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function resourceAdminUpdateFailure(error) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminUpdateFailure(%j)`,
    error
  );
  return {
    type: TYPES.RESOURCE_ADMIN_UPDATE_FAILURE,
    error: error,
  };
}

export function resourceAdminCreateRequest(data) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminCreateRequest(%j)`,
    data
  );
  return {
    type: TYPES.RESOURCE_ADMIN_CREATE_REQUEST,
  };
}

export function resourceAdminCreateSuccess(data) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminCreateSuccess(%j)`,
    data
  );
  return {
    type: TYPES.RESOURCE_ADMIN_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function resourceAdminCreateFailure(error) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminCreateFailure(%j)`,
    error
  );
  return {
    type: TYPES.RESOURCE_ADMIN_CREATE_FAILURE,
    error: error,
  };
}

export function resourceAdminDeleteRequest(id) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminDeleteRequest(${id})`
  );
  return {
    type: TYPES.RESOURCE_ADMIN_DELETE_REQUEST,
    id: id,
  };
}

export function resourceAdminDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminDeleteSuccess(${id})`
  );
  return {
    type: TYPES.RESOURCE_ADMIN_DELETE_SUCCESS,
    id: id,
    receivedAt: Date.now(),
  };
}

export function resourceAdminDeleteFailure(error) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminDeleteFailure(%j)`,
    error
  );
  return {
    type: TYPES.RESOURCE_ADMIN_DELETE_FAILURE,
    error: error,
  };
}

export function resourceAdminDeletesRequest(id) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminDeletesRequest(${id})`
  );
  return {
    type: TYPES.RESOURCE_ADMIN_DELETES_REQUEST,
    id: id,
  };
}

export function resourceAdminDeletesSuccess(id) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminDeletesSuccess(${id})`
  );
  return {
    type: TYPES.RESOURCE_ADMIN_DELETES_SUCCESS,
    id: id,
    receivedAt: Date.now(),
  };
}

export function resourceAdminDeletesFailure(error) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminDeletesFailure(%j)`,
    error
  );
  return {
    type: TYPES.RESOURCE_ADMIN_DELETES_FAILURE,
    error: error,
  };
}

export function resourceAdminFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminFormDestroy(%j)`,
    formState
  );
  return {
    type: TYPES.RESOURCE_ADMIN_FORM_DESTROY,
    form: formState,
  };
}

export function resourceAdminPatchRequest(careGuideId, id, data) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceAdminPatchRequest(${careGuideId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.RESOURCE_ADMIN_PATCH_REQUEST,
    careGuideId: careGuideId,
    id: id,
  };
}

export function resourceAdminPatchSuccess(careGuideId, id, data) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceAdminPatchSuccess(${careGuideId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.RESOURCE_ADMIN_PATCH_SUCCESS,
    careGuideId: careGuideId,
    id: id,
    receivedAt: Date.now(),
  };
}

export function resourceAdminPatchFailure(careGuideId, id, error) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourceAdminPatchFailure(${careGuideId}, ${id}, %j)`,
    error
  );
  return {
    type: TYPES.RESOURCE_ADMIN_PATCH_FAILURE,
    careGuideId: careGuideId,
    id: id,
    error: error,
  };
}

export function resourceAdminShowPreviewDetail(resourceId = null) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminShowPreviewDetail()`
  );
  return {
    type: TYPES.RESOURCE_ADMIN_SHOW_PREVIEW_DETAIL,
    resourceId: resourceId,
  };
}

export function resourceAdminHidePreviewDetail() {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceAdminHidePreviewDetail()`
  );
  return {
    type: TYPES.RESOURCE_ADMIN_HIDE_PREVIEW_DETAIL,
  };
}

export function resourceIdsAdminRequest() {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceIdsAdminRequest()`
  );
  return {
    type: TYPES.RESOURCE_ADMIN_IDS_REQUEST,
  };
}

export function resourceIdsAdminSuccess(data) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceIdsAdminSuccess(%j)`,
    data
  );
  return {
    type: TYPES.RESOURCE_ADMIN_IDS_SUCCESS,
    data: data,
  };
}

export function resourceIdsAdminFailure(error) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceIdsAdminFailure(%j)`,
    error
  );
  return {
    type: TYPES.RESOURCE_ADMIN_IDS_FAILURE,
    error: error,
  };
}

export function resourceCSVDownloadAdminRequest(partnershipId, resourceId) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceCSVDownloadAdminRequest(${partnershipId}, ${resourceId})`
  );
  return {
    type: TYPES.RESOURCE_ADMIN_CSV_DOWNLOAD_REQUEST,
  };
}

export function resourceCSVDownloadAdminSuccess() {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceCSVDownloadAdminSuccess()`
  );
  return {
    type: TYPES.RESOURCE_ADMIN_CSV_DOWNLOAD_SUCCESS,
  };
}

export function resourceCSVDownloadAdminFailure() {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] resourceCSVDownloadAdminFailure()`
  );
  return {
    type: TYPES.RESOURCE_ADMIN_CSV_DOWNLOAD_FAILURE,
  };
}

export function resourcesAdminPatchRequest(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourcesAdminPatchRequest(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.RESOURCES_ADMIN_PATCH_REQUEST,
    partnershipId: partnershipId,
  };
}

export function resourcesAdminPatchSuccess(partnershipId) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourcesAdminPatchSuccess(${partnershipId})`
  );
  return {
    type: TYPES.RESOURCES_ADMIN_PATCH_SUCCESS,
    partnershipId: partnershipId,
    receivedAt: Date.now(),
  };
}

export function resourcesAdminPatchFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.resources.actions] resourcesAdminPatchFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.RESOURCES_ADMIN_PATCH_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadResourcesAdmin(
  partnershipId = null,
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] loadResourcesAdmin(${partnershipId}, ${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(
      resourceAdminListRequest(partnershipId, page, limit, order, filter)
    );

    // call API
    const response = await api.getResourcesAdmin(
      partnershipId,
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get resources admin list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resources admin list success. Partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'resources']),
        [schema.resourceAdmin]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceAdminListSuccess(partnershipId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resources admin success [empty]. Partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(resourceAdminListSuccess(partnershipId, data));
      success = true;

      // get resources admin list failure
    } else {
      Logger.log(
        'info',
        `Get API resources admin list failure. Partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        resourceAdminListFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function loadResourcesPinnedAdmin(
  partnershipId = null,
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] loadResourcesPinnedAdmin(${partnershipId}, ${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(
      resourceAdminListPinnedRequest(partnershipId, page, limit, order, filter)
    );

    // call API
    const response = await api.getResourcesPinnedAdmin(
      partnershipId,
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get resources admin list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resources admin list success. Partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'resources']),
        [schema.resourceAdmin]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceAdminListPinnedSuccess(partnershipId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resources admin success [empty]. Partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(resourceAdminListPinnedSuccess(partnershipId, data));
      success = true;

      // get resources admin list failure
    } else {
      Logger.log(
        'info',
        `Get API resources admin list failure. Partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        resourceAdminListPinnedFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function loadResourcesDashboardAdmin(
  partnershipId = null,
  page = 1,
  limit = 10,
  order = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] loadResourcesDashboardAdmin(${partnershipId}, ${page}, ${limit}, ${order})`
  );

  return async function (dispatch) {
    dispatch(
      resourceAdminListDashboardRequest(partnershipId, page, limit, order)
    );

    // call API
    const response = await api.getResourcesDashboardAdmin(
      partnershipId,
      page,
      limit,
      order
    );
    let success = false;

    // get resources admin list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resources admin list success. Partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'resources']),
        [schema.resourceAdmin]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceAdminListDashboardSuccess(partnershipId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resources admin success [empty]. Partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(resourceAdminListDashboardSuccess(partnershipId, data));
      success = true;

      // get resources admin list failure
    } else {
      Logger.log(
        'info',
        `Get API resources admin list failure. Partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        resourceAdminListDashboardFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function loadResourceIdsAdmin(
  partnership_id = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] loadResourcesAdmin(${partnership_id}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(resourceIdsAdminRequest());

    // call API
    const response = await api.getResourceIdsAdmin(partnership_id, filter);
    let success = false;
    Logger.log('error', 'API.loadResourceIdsAdminresponse');
    // get resource ids success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API resource ids success.`);
      const data = response.getIn(['data', 'resources']);
      dispatch(resourceIdsAdminSuccess(data));
      success = true;
    }
    // get resource ids failure
    else if (204 === response.get('status')) {
      Logger.log('info', `Get API resource ids success.`);
      dispatch(resourceIdsAdminSuccess([]));
      success = true;
      // get resource ids failure
    } else {
      Logger.log('info', `Get API resource ids failure.`);
      dispatch(resourceIdsAdminSuccess(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadResourceAdmin(partnership_id, id, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] loadResourceAdmin(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(resourceAdminReadRequest(id));

    // call API
    const response = await api.getResourceAdmin(partnership_id, id);
    let success = false;

    // get resource success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API resource success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource'])],
        [schema.resourceAdmin]
      );
      const data = {
        id: response.getIn(['data', 'resource', 'id']),
      };
      dispatch(addEntities(normalizedEntities));
      dispatch(resourceAdminReadSuccess(data));
      success = true;

      // get resource failure
    } else {
      Logger.log('info', `Get API resource failure. ID: ${id}.`);
      dispatch(resourceAdminReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateResourceAdmin(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] updateResourceAdmin(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(resourceAdminUpdateRequest(id, data));

    // call API
    const response = await api.putResourceAdmin(id, data);
    let success = false;

    // put resource success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API resource success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource'])],
        [schema.resourceAdmin]
      );
      const data = {
        id: response.getIn(['data', 'resource', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceAdminUpdateSuccess(data));
      success = true;

      // get resource failure
    } else {
      Logger.log('info', `PUT API resource failure. ID: ${id}.`);
      dispatch(resourceAdminUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createResourceAdmin(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] createResourceAdmin(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(resourceAdminCreateRequest(data));

    // call API
    const response = await api.postResourcesAdmin(data);
    let success = false;

    // post resourcesAdmin success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API resourcesAdmin success. Post: ${response.getIn([
          'data',
          'resource',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource'])],
        [schema.resourceAdmin]
      );
      const data = {
        id: response.getIn(['data', 'resource', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceAdminCreateSuccess(data));
      success = true;

      // get resourcesAdmin failure
    } else {
      Logger.log('info', `POST API resourcesAdmin failure.`);
      dispatch(resourceAdminCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deleteResourcesAdmin(
  partnership_id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] deleteResourcesAdmin(${partnership_id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(resourceAdminDeletesRequest(partnership_id));

    // call API
    const response = await api.deleteResourcesAdmin(partnership_id, data);
    let success = false;

    // post resourcesAdmin success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `DELETE API resources success. ID: ${partnership_id}.`
      );
      dispatch(resourceAdminDeletesSuccess(partnership_id));
      success = true;

      // get resourcesAdmin failure
    } else {
      Logger.log('info', `DELETE API resource failure. ID: ${partnership_id}.`);
      dispatch(resourceAdminDeletesFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deleteResourceAdmin(partnership_id, id, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] deleteResourceAdmin(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(resourceAdminDeleteRequest(id));

    // call API
    const response = await api.deleteResourceAdmin(partnership_id, id);
    let success = false;

    // delete resource success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API resource success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'resourcesAdmin', id: id }));
      dispatch(resourceAdminDeleteSuccess(id));
      success = true;

      // get resource failure
    } else {
      Logger.log('info', `DELETE API resource failure. ID: ${id}.`);
      dispatch(resourceAdminDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function patchResourceAdmin(
  partnershipId,
  id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] patchResourceAdmin(${partnershipId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(resourceAdminPatchRequest(partnershipId, id, data));
    // call API
    const response = await api.patchResourceAdmin(partnershipId, id, data);
    let success = false;

    // patch resource success
    if (200 === response.get('status')) {
      Logger.log('info', `PATCH API resource success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource'])],
        [schema.resourceAdmin]
      );
      const data = {
        id: response.getIn(['data', 'resource', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceAdminPatchSuccess(id, data));
      success = true;

      // patch resource failure
    } else {
      Logger.log('info', `PATCH API resource failure. ID: ${id}.`);
      dispatch(
        resourceAdminPatchFailure(id, response.getIn(['data', 'error']))
      );
    }
    // callback function
    cb(success);
  };
}

export function patchResourcesAdmin(partnershipId, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] patchResourcesAdmin(${partnershipId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(resourcesAdminPatchRequest(partnershipId, data));

    // call API
    const response = await api.patchResourcesAdmin(partnershipId, data);
    let success = false;

    // patch resource success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `PATCH API resource success. Partnership ID: ${partnershipId}.`
      );

      dispatch(resourcesAdminPatchSuccess(partnershipId));
      success = true;

      // patch resource failure
    } else {
      Logger.log(
        'info',
        `PATCH API resource failure. ID: Partnership ID: ${partnershipId}.`
      );
      dispatch(
        resourcesAdminPatchFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }
    // callback function
    cb(success);
  };
}

export function downloadResourceCSVAdmin(
  partnershipId = null,
  partnershipName = null,
  resourceId = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resourcesAdmin.actions] downloadResourceCSVAdmin()`
  );

  return async function (dispatch) {
    dispatch(resourceCSVDownloadAdminRequest(partnershipId, resourceId));

    // call API
    const response = await api.getResourcesCsv(partnershipId, resourceId);
    let success = false;

    // get resource CSV success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API resource CSV success.`);

      const now = new Date();

      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-${partnershipName}-resource-${Format.date(
          now,
          'YYYY-MM-DDTHHmm'
        )}.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      dispatch(resourceCSVDownloadAdminSuccess());
      success = true;

      // get resources CSV failure
    } else {
      Logger.log('info', `Get API resources CSV failure.`);
      dispatch(resourceCSVDownloadAdminFailure());
    }

    cb(success);
  };
}

Logger.log('silly', `resourcesAdmin.actions loaded.`);
