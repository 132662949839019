import React, { useState, useEffect } from 'react';
import { Col, Row, Typography, Button } from 'antd';
import { Translation, getI18n } from 'react-i18next';
import { withRouter } from 'react-router';
import DailyLogsList from './DailyLogsList';
import ConfirmationModal from '../../../elements/components/ConfirmationModal';
import notification from '../../../elements/lib/NotificationWrapper';
import io from 'socket.io-client';
import api from '../../../../../state/api';
import { pathTo } from '../../../Routes';
import QueryString from '../../../../../lib/QueryString';
import Config from '../../../../../Config';

const { Title } = Typography;

const confirmTexts = {
  title: getI18n().t('care_guide_owners_cognito_confirm_modal_title'),
  description: getI18n().t(
    'care_guide_owners_cognito_confirm_modal_description'
  ),
  leaveText: getI18n().t('care_guide_owners_cognito_confirm_modal_leave'),
  saveText: getI18n().t('care_guide_owners_cognito_confirm_modal_save'),
};

const DailyLogsScreen = ({
  cognitoId,
  cognitoKey,
  careGuide,
  profileId,
  list,
  load,
  careGuideId,
  partnershipId,
  component,
  history,
  remove,
  generatePDF,
  isGeneratingPDF,
  page,
  limit,
  filter,
  order,
  total,
  ...props
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [dailyLogId, setDailyLogId] = useState(null);
  const [newEntry, setNewEntry] = useState(false);
  const [showCognito, setShowCognito] = useState(true);
  const [waitEntries, setWaitEntries] = useState(0);
  const [activeKey, setActiveKey] = useState([]);

  const filterString = JSON.stringify(filter);

  const resetSurvey = () => {
    setShowCognito(false);
    setNewEntry(false);
    setTimeout(() => {
      setShowCognito(true);
    }, 100);
  };

  const onDeleteConfirmModal = () => {
    setConfirmationModalOpen(false);
    remove(partnershipId, careGuideId, dailyLogId, (success) => {
      if (success) {
        load(
          partnershipId,
          careGuideId,
          page,
          limit,
          order,
          JSON.parse(filterString)
        );
        notification.success(
          getI18n().t('care_guide_owners_cognito_daily_logs_delete_success')
        );
      } else {
        notification.success(
          getI18n().t('care_guide_owners_cognito_daily_logs_delete_error')
        );
      }
    });
    setDailyLogId(null);
  };

  const onCancelConfirmModal = () => {
    setConfirmationModalOpen(false);
    setDailyLogId(null);
  };

  useEffect(() => {
    if (!cognitoId || !cognitoKey || showCognito === false) return;

    const script = document.createElement('script');
    script.src = 'https://www.cognitoforms.com/f/seamless.js';
    script.dataset.key = cognitoKey;
    script.dataset.form = cognitoId;
    script.async = true;

    script.onload = () => {
      if (window.Cognito) {
        window.Cognito.mount(cognitoId, '#cognito')
          .prefill({
            Name: `${careGuide.first_name} ${careGuide.last_name}`,
            CareGuideId: careGuide.id,
            PartnershipId: careGuide.partnership.id,
            UserProfileId: profileId,
          })
          .on('afterSubmit', function (e) {
            setWaitEntries((prevState) => prevState + 1);
            setNewEntry(true);
          });
      }
    };
    document.getElementById('cognito').appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoId, cognitoKey, showCognito]);

  useEffect(() => {
    const socket = io(Config.get('API_BASE_URL_SOCKET'), {
      extraHeaders: {
        Authorization: api._getAuthToken(),
      },
      query: {
        app_key: api.app_key,
      },
    });

    socket.on('answer_cognito', (data) => {
      const { status } = data;
      if (status === true) {
        setWaitEntries((prevState) => prevState - 1);
        setActiveKey([]);
        const path = pathTo(component, {
          id: careGuideId,
          page: 1,
          limit: 10,
        });
        history.push(QueryString.append(path, { order }));
        if (page === 1) {
          load(partnershipId, careGuideId, 1, 10, order, {});
        }
      }
    });
    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('answer_cognito');
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Translation>
      {(t) => (
        <>
          <Row>
            <Col
              xs={24}
              md={24}
              lg={12}
              xl={12}
              flex="auto"
              className="section-daily-log"
            >
              <Row className="info-daily-log">
                <Col span={24} className={`info-col`}>
                  {newEntry && (
                    <>
                      <Row
                        style={{ backgroundColor: 'white', padding: '10px' }}
                        justify="space-between"
                      >
                        <Col>
                          <Title level={3}>
                            {t(
                              'care_guide_owners_cognito_daily_logs_reset_title'
                            )}
                          </Title>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            size="large"
                            onClick={resetSurvey}
                          >
                            {t(
                              'care_guide_owners_cognito_daily_logs_reset_button'
                            )}
                          </Button>
                        </Col>
                      </Row>

                      <br></br>
                    </>
                  )}
                  {showCognito && (
                    <div style={{ backgroundColor: 'white', padding: '10px' }}>
                      <div id="cognito"></div>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              md={24}
              lg={12}
              xl={12}
              flex="auto"
              className="section-daily-log"
            >
              <Row className="info-daily-log">
                <DailyLogsList
                  activeKey={activeKey}
                  setActiveKey={setActiveKey}
                  waitEntries={waitEntries}
                  careGuide={careGuide}
                  list={list}
                  load={load}
                  careGuideId={careGuideId}
                  partnershipId={partnershipId}
                  component={component}
                  page={page}
                  limit={limit}
                  filter={filter}
                  order={order}
                  total={total}
                  setConfirmationModalOpen={setConfirmationModalOpen}
                  setDailyLogId={setDailyLogId}
                  generatePDF={generatePDF}
                  isGeneratingPDF={isGeneratingPDF}
                />
              </Row>
            </Col>
          </Row>
          <ConfirmationModal
            texts={confirmTexts}
            openConfirmationModal={confirmationModalOpen}
            onLeaveConfirmation={onDeleteConfirmModal}
            onSaveConfirmation={onCancelConfirmModal}
            onCancel={onCancelConfirmModal}
            setConfirmationModalOpen={setConfirmationModalOpen}
          />
        </>
      )}
    </Translation>
  );
};

export default withRouter(DailyLogsScreen);
