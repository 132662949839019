import { Map } from 'immutable';

const Config = Map({
  ENVIRONMENT: process.env.NODE_ENV, // production, development, test
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  API_BASE_URL_STRAPI: process.env.REACT_APP_API_BASE_URL_STRAPI,
  API_BASE_URL_SOCKET: process.env.REACT_APP_API_BASE_URL_SOCKET,
  STAPI_TOKEN: process.env.REACT_APP_STRAPI_TOKEN,
  MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,
  API_APP_KEY: process.env.REACT_APP_API_APP_KEY,
  X_API_KEY: process.env.REACT_APP_X_API_KEY,
  LOG_LEVEL: process.env.REACT_APP_LOG_LEVEL, // error, warn, info, verbose, debug, silly
  IMAGE_CACHE_URL: process.env.REACT_APP_IMAGE_CACHE_URL, // scheme + domain to access cached images
  REACT_BEACON_ID: process.env.REACT_APP_BEACON_ID, // Beacon ID for chat/support script
  STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY, // Stripe API key for Stripe script
  REQUIRE_REGISTRATION_CODE: Boolean(
    process.env.REACT_APP_REQUIRE_REGISTRATION_CODE
  ), // Flag to require registration code during registration
  USERGUIDING_IDENTIFY_USER: Boolean(
    process.env.REACT_APP_USERGUIDING_IDENTIFY_USER
  ), // Flag to identify user with User Guiding

  AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  AWS_S3_BUCKET: process.env.REACT_APP_AWS_S3_BUCKET,
  AWS_ARCHIVE_BUCKET: process.env.REACT_APP_AWS_ARCHIVE_BUCKET,

  UI_COLLAPSE_SIDEBAR_WIDTH: 1024, // screen width at which sidebar collapses automatically

  LANGUAGES: ['en-US', 'es-MX'], // list of supported languages in four-letter format, default is first
  SESSION_TIMEOUT_COUNTDOWN: 60, // number of seconds to warn user before logging user out automatically
  AUTO_LOGOUT_COUNTDOWN: 15, // number of seconds to automatically log a user out if they requested log out but didn't confirm

  DEFAULT_LOGIN_SCREEN: 'LoginScreen', // routing key for default login screen
  DEFAULT_LOGIN_REDIRECT: 'HomeScreen', // routing key for screen to redirect user to after successful authentication
  DEFAULT_REGISTRATION_REDIRECT: 'DashboardScreen', // routing key for screen to redirect user to after successful registration
  DEFAULT_BREADCRUMB_HOME: 'DashboardScreen', // routing key for screen to use as "home" in breadcrumbs
  DEFAULT_BREADCRUMB_HOME_ADMIN: 'HomeScreen', // routing key for screen to use as "home" in breadcrumbs

  DEFAULT_AUTH_STORAGE: 'session', // session, local
  DEFAULT_LIST_LENGTH: 10,
  DEFAULT_MESSAGE_TIMEOUT: 4, // number of seconds to show status messages - such as success/failure to update a record
  DEFAULT_DATE_FORMAT: 'MM/DD/YYYY',
  DEFAULT_DATETIME_FORMAT: 'MMM D, YYYY [at] h:mm A',

  API_DATETIME_FORMAT: 'YYYY-MM-DDTHH:mm:ssZZ',

  MODULE_TOGGLES: Map({
    session: { enabled: true, routes: true },
    dashboard: { enabled: true, routes: true },
    userAccount: { enabled: true, routes: true },
    register: { enabled: true, routes: true },
    relationships: { enabled: true, routes: false },
    careGuides: { enabled: true, routes: true },
    careGuideHelpers: { enabled: true, routes: true },
    careGuideHelperTags: { enabled: true, routes: false },
    careGuideInvitations: { enabled: true, routes: true },
    journalPosts: { enabled: true, routes: true, keywords: true },
    journalReplies: { enabled: true, routes: false },
    journalTags: { enabled: true, routes: false },
    journalEmojis: { enabled: true, routes: false },
    calendar: { enabled: true, routes: true },
    messages: { enabled: true, routes: true },
    resources: { enabled: true, routes: true },
    resourceTags: { enabled: true, routes: false },
    resourceServiceTags: { enabled: true, routes: false },
    resourceNotes: { enabled: true, routes: false },
    contacts: { enabled: true, routes: false },
    media: { enabled: true, routes: false },
    alerts: { enabled: true, routes: false },
    search: { enabled: false, routes: false },
    content: { enabled: true, routes: true },
    timezones: { enabled: true, routes: false },
    payments: {
      enabled: Boolean(process.env.REACT_APP_STRIPE_API_KEY),
      routes: true,
    },
    plans: { enabled: true, routes: false },
    accounts: { enabled: true, routes: false },
    locations: { enabled: true, routes: false },
    partnerships: { enabled: true, routes: false },
    userProfilesPartnerships: { enabled: true, routes: false },
    admin: { enabled: true, routes: true },
    registrationCodes: { enabled: true, routes: true },
    plansAdmin: { enabled: true, routes: false },
    journalPartnerTagsAdmin: { enabled: true, routes: true },
    home: { enabled: true, routes: true },
    journalPartnerTags: { enabled: true, routes: false },
    userProfilesPartnershipsAdmin: { enabled: true, routes: true },
    calendarEventsAdmin: { enabled: true, routes: true },
    careGuidesMembersPartnership: { enabled: true, routes: false },
    careGuidesPartnership: { enabled: true, routes: false },
    partnershipAccount: { enabled: true, routes: true },
    resourcesAdmin: { enabled: true, routes: true },
    careGuidesAdmin: { enabled: true, routes: true },
    learnings: { enabled: true, routes: true },
    careGuidePartnerTagsAdmin: { enabled: true, routes: true },
    adminPartnerClasses: { enabled: true, routes: false },
    careGuideOwners: { enabled: true, routes: true },
    adminPartnerClassesAdmin: { enabled: true, routes: false },
    careGuideNotes: { enabled: true, routes: false },
    careGuideDocuments: { enabled: true, routes: false },
    chat: { enabled: true, routes: true },
    partnershipFormAnswers: { enabled: true, routes: false },
    dashboardAdmin: { enabled: true, routes: true },
    dashboardSections: { enabled: true, routes: false },
    recommendedUtilities: { enabled: true, routes: false },
  }),
});

export default Config;
