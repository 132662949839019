import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'RECOMMENDED_UTILITIES/LIST_REQUEST',
  LIST_SUCCESS: 'RECOMMENDED_UTILITIES/LIST_SUCCESS',
  LIST_FAILURE: 'RECOMMENDED_UTILITIES/LIST_FAILURE',
  CREATE_REQUEST: 'RECOMMENDED_UTILITIES/CREATE_REQUEST',
  CREATE_SUCCESS: 'RECOMMENDED_UTILITIES/CREATE_SUCCESS',
  CREATE_FAILURE: 'RECOMMENDED_UTILITIES/CREATE_FAILURE',
  UPDATE_REQUEST: 'RECOMMENDED_UTILITIES/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'RECOMMENDED_UTILITIES/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'RECOMMENDED_UTILITIES/UPDATE_FAILURE',
  FORM_DESTROY: 'RECOMMENDED_UTILITIES/FORM_DESTROY',
  SHOW_FORM: 'RECOMMENDED_UTILITIES/SHOW_FORM',
  HIDE_FORM: 'RECOMMENDED_UTILITIES/HIDE_FORM',
  PATCH_REQUEST: 'RECOMMENDED_UTILITIES/PATCH_REQUEST',
  PATCH_SUCCESS: 'RECOMMENDED_UTILITIES/PATCH_SUCCESS',
  PATCH_FAILURE: 'RECOMMENDED_UTILITIES/PATCH_FAILURE',
  DELETE_REQUEST: 'RECOMMENDED_UTILITIES/DELETE_REQUEST',
  DELETE_SUCCESS: 'RECOMMENDED_UTILITIES/DELETE_SUCCESS',
  DELETE_FAILURE: 'RECOMMENDED_UTILITIES/DELETE_FAILURE',
  DOCUMENT_UPLOAD_REQUEST: 'MEDIA/DOCUMENT_UPLOAD_REQUEST',
  DOCUMENT_UPLOAD_SUCCESS: 'MEDIA/DOCUMENT_UPLOAD_SUCCESS',
  DOCUMENT_UPLOAD_FAILURE: 'MEDIA/DOCUMENT_UPLOAD_FAILURE',
  DOCUMENT_DOWNLOAD_REQUEST: 'MEDIA/DOCUMENT_DOWNLOAD_REQUEST',
  DOCUMENT_DOWNLOAD_SUCCESS: 'MEDIA/DOCUMENT_DOWNLOAD_SUCCESS',
  DOCUMENT_DOWNLOAD_FAILURE: 'MEDIA/DOCUMENT_DOWNLOAD_FAILURE',
  DOCUMENT_DESTROY_REQUEST: 'MEDIA/DOCUMENT_DESTROY_REQUEST',
  DOCUMENT_DESTROY_SUCCESS: 'MEDIA/DOCUMENT_DESTROY_SUCCESS',
  DOCUMENT_DESTROY_FAILURE: 'MEDIA/DOCUMENT_DESTROY_FAILURE',
};

export function recommendedUtilityListRequest(
  partnershipId,
  page,
  limit,
  order
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityListRequest(${partnershipId}, ${page}, ${limit}, ${order})`
  );
  return {
    type: TYPES.LIST_REQUEST,
    partnershipId: partnershipId,
    page: page,
    limit: limit,
    order: order,
  };
}

export function recommendedUtilityListSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityListSuccess(%j)`,
    data
  );
  return {
    type: TYPES.LIST_SUCCESS,
    partnershipId: partnershipId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityListFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityListFailure(%j)`,
    error
  );
  return {
    type: TYPES.LIST_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function recommendedUtilityUpdateRequest(partnershipId, id, data) {
  return {
    type: TYPES.UPDATE_REQUEST,
    partnershipId: partnershipId,
    id: id,
    data: data,
  };
}

export function recommendedUtilityUpdateSuccess(partnershipId, id, data) {
  return {
    type: TYPES.UPDATE_SUCCESS,
    partnershipId: partnershipId,
    id: id,
    data: data,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityUpdateFailure(partnershipId, error) {
  return {
    type: TYPES.UPDATE_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function recommendedUtilityFormDestroy(formState = null) {
  return {
    type: TYPES.FORM_DESTROY,
    form: formState,
  };
}

export function recommendedUtilityShowForm(id = null) {
  return {
    type: TYPES.SHOW_FORM,
    id: id,
  };
}

export function recommendedUtilityHideForm() {
  return {
    type: TYPES.HIDE_FORM,
  };
}

export function recommendedUtilityCreateRequest(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityCreateRequest(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_REQUEST,
    partnershipId: partnershipId,
    data: data,
  };
}

export function recommendedUtilityCreateSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityCreateSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_SUCCESS,
    partnershipId: partnershipId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityCreateFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityCreateFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.CREATE_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function recommendedUtilityUploadRequest() {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityUploadRequest()`
  );
  return {
    type: TYPES.DOCUMENT_UPLOAD_REQUEST,
  };
}

export function recommendedUtilityUploadSuccess() {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityUploadSuccess()`
  );
  return {
    type: TYPES.DOCUMENT_UPLOAD_SUCCESS,
  };
}

export function recommendedUtilityUploadFailure(error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityUploadFailure(%j)`,
    error
  );
  return {
    type: TYPES.DOCUMENT_UPLOAD_FAILURE,
    error: error,
  };
}

export function recommendedUtilityDownloadRequest() {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDownloadRequest()`
  );
  return {
    type: TYPES.DOCUMENT_DOWNLOAD_REQUEST,
  };
}

export function recommendedUtilityDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDownloadSuccess()`
  );
  return {
    type: TYPES.DOCUMENT_DOWNLOAD_SUCCESS,
  };
}

export function recommendedUtilityDownloadFailure(error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDownloadFailure(%j)`,
    error
  );
  return {
    type: TYPES.DOCUMENT_DOWNLOAD_FAILURE,
    error: error,
  };
}

export function recommendedUtilityDestroyRequest() {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDestroyRequest()`
  );
  return {
    type: TYPES.DOCUMENT_DESTROY_REQUEST,
  };
}

export function recommendedUtilityDestroySuccess() {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDestroySuccess()`
  );
  return {
    type: TYPES.DOCUMENT_DESTROY_SUCCESS,
  };
}

export function recommendedUtilityDestroyFailure(error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDestroyFailure(%j)`,
    error
  );
  return {
    type: TYPES.DOCUMENT_DESTROY_FAILURE,
    error: error,
  };
}

export function recommendedUtilityPatchRequest(partnershipId, id, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityPatchRequest(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_REQUEST,
    partnershipId: partnershipId,
    id: id,
    data: data,
  };
}

export function recommendedUtilityPatchSuccess(partnershipId, id, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityPatchSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_SUCCESS,
    partnershipId: partnershipId,
    id: id,
    data: data,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityPatchFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDestroyFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.PATCH_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function recommendedUtilityDeleteRequest(partnershipId, id) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDeleteRequest(${partnershipId}, ${id})`
  );
  return {
    type: TYPES.DELETE_REQUEST,
    partnershipId: partnershipId,
    id: id,
  };
}

export function recommendedUtilityDeleteSuccess(partnershipId, id) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDeleteSuccess(${id})`
  );
  return {
    type: TYPES.DELETE_SUCCESS,
    partnershipId: partnershipId,
    id: id,
  };
}

export function recommendedUtilityDeleteFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDeleteFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.DELETE_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function createRecommendedUtility(
  partnershipId,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] createRecommendedUtility(${partnershipId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityCreateRequest(partnershipId, data));

    // call API
    const response = await api.postRecommendedUtility(partnershipId, data);
    let success = false;

    // post recommended utility success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API recommended utility success. Partnership ID: ${partnershipId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'recommended_utility'])],
        [schema.recommendedUtility]
      );
      const data = {
        id: response.getIn(['data', 'recommended_utility', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityCreateSuccess(partnershipId, data));
      success = true;

      // post recommended utility failure
    } else {
      Logger.log(
        'info',
        `POST API recommended utility failure. Partnership ID: ${partnershipId}.`
      );
      dispatch(
        recommendedUtilityCreateFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function updateRecommendedUtility(
  partnershipId,
  id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] updateRecommendedUtility(${partnershipId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityUpdateRequest(partnershipId, id, data));

    // call API
    const response = await api.putRecommendedUtility(partnershipId, id, data);
    let success = false;

    // put recommended utility success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `UPDATE API recommended utility success. Partnership ID: ${partnershipId}, User: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'recommended_utility'])],
        [schema.recommendedUtility]
      );
      const data = {
        id: response.getIn(['data', 'recommended_utility', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityUpdateSuccess(partnershipId, data));
      success = true;

      // put recommended utility failure
    } else {
      Logger.log(
        'info',
        `UPDATE API recommended utility failure. Partnership ID: ${partnershipId}, ID: ${id}.`
      );
      dispatch(
        recommendedUtilityUpdateFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function destroyDocument(path, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] destroyDocument(${path}, ###)`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityDestroyRequest());

    // call API
    const response = await api.deleteDocumentAWS(path);
    let success = false;

    // put document success
    if (response['success']) {
      Logger.log('info', `Delete S3 document success.`);
      success = true;
      dispatch(recommendedUtilityDestroySuccess());

      // put document failure
    } else {
      Logger.log('info', `Delete S3 document failure.`);
      dispatch(recommendedUtilityDestroyFailure('Delete S3 error.'));
    }

    // callback function
    cb(success);
  };
}

export function loadRecommendedUtilities(
  partnershipId = null,
  page = 1,
  limit = 15,
  order = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] loadRecommendedUtilities(${partnershipId}, ${page}, ${limit}, ${order})`
  );
  return async function (dispatch) {
    dispatch(recommendedUtilityListRequest(partnershipId, page, limit, order));

    // call API
    const response = await api.getRecommendedUtilities(
      partnershipId,
      page,
      limit,
      order
    );
    let success = false;

    // get recommended utilities list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API recommended utilities list success. Partnership ID: ${partnershipId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'recommended_utilities']),
        [schema.recommendedUtility]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityListSuccess(partnershipId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API recommended utilities list success [empty]. Partnership ID: ${partnershipId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };

      dispatch(recommendedUtilityListSuccess(partnershipId, data));
      success = true;

      // get recommended utilities list failure
    } else {
      Logger.log(
        'info',
        `Get API recommended utilities list failure. Partnership ID: ${partnershipId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        recommendedUtilityListFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function uploadDocument(path, file, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] uploadDocument(${path}, ###, ###)`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityUploadRequest());

    // call API
    const response = await api.putDocumentUpload(path, file);
    let success = false;

    // put document success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT S3 document success.`);
      success = true;
      dispatch(recommendedUtilityUploadSuccess());

      // put document failure
    } else {
      Logger.log('info', `PUT S3 document failure.`);
      dispatch(recommendedUtilityUploadFailure('Upload error.'));
    }

    // callback function
    cb(success);
  };
}

export function patchRecommendedUtility(
  partnershipId = null,
  id = null,
  data = {},
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] patchRecommendedUtility(${partnershipId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityPatchRequest(partnershipId, id, data));

    // call API
    const response = await api.patchRecommendedUtility(partnershipId, id, data);
    let success = false;

    // patch recommended Utility success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Patch API recommended Utility success. Partnership ID: ${partnershipId} ID: ${id}.`
      );
      const normalizedEntities = normalize(
        [response.getIn(['data', 'recommended_utility'])],
        [schema.careGuideDocument]
      );
      const data = {
        id: response.getIn(['data', 'recommended_utility', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityPatchSuccess(partnershipId, id, data));
      success = true;

      // patch recommended Utility failure
    } else {
      Logger.log(
        'info',
        `Patch API recommended Utility failure. Partnership ID: ${partnershipId}.`
      );
      dispatch(
        recommendedUtilityPatchFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteRecommendedUtility(
  partnershipId = null,
  id = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] deleteRecommendedUtility(${partnershipId}, ${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityDeleteRequest(partnershipId, id));

    // call API
    const response = await api.deleteRecommendedUtility(partnershipId, id);
    let success = false;

    // delete recommended Utility success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `Delete API recommended Utility success. Partnership ID: ${partnershipId} ID: ${id}.`
      );
      dispatch(recommendedUtilityDeleteSuccess(partnershipId, id));
      success = true;

      // get recommended Utility failure
    } else {
      Logger.log(
        'info',
        `Delete API recommended Utility failure. Partnership ID: ${partnershipId}  ID: ${id}.`
      );
      dispatch(
        recommendedUtilityDeleteFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function downloadDocument(path, fileName, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] downloadDocument(${path}, ###)`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityDownloadRequest());

    // call API
    const response = await api.createGetPresignedUrlAWS(path, fileName);
    let success = false;

    let url = null;

    // create presigned url success
    if (response['success']) {
      Logger.log('info', `Create presigned URL success.`);
      success = true;

      url = response['presignedUrl'];

      dispatch(recommendedUtilityDownloadSuccess());

      // create presigned url failure
    } else {
      Logger.log('info', `Create presigned URL failure.`);
      dispatch(recommendedUtilityDownloadFailure('Download error.'));
    }

    // callback function
    cb(success, url);
  };
}

Logger.log('silly', `state.recommendedUtilities.actions loaded.`);
