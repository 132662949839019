import { connect } from 'react-redux';
import DashboardAdminRecommendedUtilities from '../components/DashboardAdminRecommendedUtilities';
import storage from '../../../../../lib/Storage';

import { schema } from '../../../../../state/schema';
import {
  loadRecommendedUtilities,
  recommendedUtilityShowForm,
  downloadDocument,
  patchRecommendedUtility,
  deleteRecommendedUtility,
} from '../../../../../state/modules/recommendedUtilities/actions';
import { denormalize } from 'normalizr';

const mapStateToProps = (state, ownProps) => {
  const order = 'created_at.asc';
  const limit = 5;
  const page_recommended_utilities =
    parseInt(ownProps.page_recommended_utilities) ?? 1;

  const partnershipId = storage.get('local', 'partnershipId');

  const result = state.recommendedUtilities.getIn([
    'pages',
    partnershipId,
    order,
    limit,
    page_recommended_utilities,
  ]);

  return {
    page_recommended_utilities,
    order,
    limit,
    partnershipId,
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['recommendedUtilities', x]),
                schema.recommendedUtility,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
      : [],
    isLoading:
      state.recommendedUtilities.get('isLoading') ||
      state.recommendedUtilities.get('isFileDownloading'),
    total: state.recommendedUtilities.get('total') ?? 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnershipId, page, limit, order, cb) => {
      dispatch(loadRecommendedUtilities(partnershipId, page, limit, order, cb));
    },
    showForm: (recommendedUtilityId) => {
      dispatch(recommendedUtilityShowForm(recommendedUtilityId));
    },
    download: (path, fileName, cb) => {
      dispatch(downloadDocument(path, fileName, cb));
    },
    patch: (partnershipId, id, data, cb) => {
      dispatch(patchRecommendedUtility(partnershipId, id, data, cb));
    },
    remove: (partnershipId, id, cb) => {
      dispatch(deleteRecommendedUtility(partnershipId, id, cb));
    },
  };
};

const DashboardAdminRecommendedUtilitiesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardAdminRecommendedUtilities);

export default DashboardAdminRecommendedUtilitiesContainer;
