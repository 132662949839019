import React, { useState } from 'react';
import { Col, Row, Typography, Collapse, Pagination, Button } from 'antd';
import { Translation } from 'react-i18next';

import {
  FileOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import Format from '../../../../../lib/Format';
import { withRouter } from 'react-router';

const { Text } = Typography;
const { Panel } = Collapse;

const DashboardAdminDailyLogs = ({ list, ...props }) => {
  const [activeKey, setActiveKey] = useState([]);

  const onChangeCollapse = (key) => {
    setActiveKey(key);
  };

  const isActiveKey = (key) => activeKey.includes(key);

  const transformationText = (text) => {
    return Array.isArray(text.split(/(?=[A-Z])/))
      ? text.split(/(?=[A-Z])/).join(' ') + ': '
      : text.split(/(?=[A-Z])/)[0] + ': ';
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Row className="section-daily-dashboard">
            <Col flex="auto" className={`info-row`}>
              <>
                <Collapse
                  style={{ backgroundColor: 'white' }}
                  activeKey={activeKey}
                  onChange={onChangeCollapse}
                >
                  {list
                    ? list.map((x) => (
                        <>
                          {x?.answers ? (
                            <Panel
                              header={
                                <>
                                  <Text
                                    type={!isActiveKey(x.key) && 'secondary'}
                                    style={{
                                      fontSize: '16px',
                                      color: isActiveKey(x.key) && '#4279BD',
                                    }}
                                  >
                                    {Format.dateTable(x.created_at)}
                                  </Text>
                                  {isActiveKey(x.key) ? (
                                    <div
                                      style={{
                                        marginLeft: '12px',
                                        textAlign: 'center',
                                      }}
                                    >
                                      <CaretDownOutlined />
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        marginLeft: '12px',
                                        textAlign: 'center',
                                      }}
                                    >
                                      <CaretUpOutlined />
                                    </div>
                                  )}
                                </>
                              }
                              key={x.key}
                              showArrow={false}
                            >
                              <Row key={x.key}>
                                <Col flex="auto">
                                  <Text strong>
                                    {t('care_guide_owners_cognito_field_name') +
                                      ': '}
                                  </Text>
                                  <Text type="secondary">
                                    {x?.answers
                                      .filter((item) => item.key === 'Name')
                                      .map((item) => {
                                        return item.value;
                                      })}
                                  </Text>
                                </Col>
                              </Row>
                              <>
                                {x?.answers
                                  .filter(
                                    (item) =>
                                      item.key !== 'Name' &&
                                      item.key !== 'Comments'
                                  )
                                  .map((item, index, array) => {
                                    if (index % 2 === 0) {
                                      const nextEntry = array[index + 1];
                                      return (
                                        <Row
                                          gutter={24}
                                          style={{ marginTop: '20px' }}
                                          key={index}
                                        >
                                          <Col span={11}>
                                            <Text strong>
                                              {transformationText(item.key)}
                                            </Text>
                                            <Text type="secondary">
                                              {Array.isArray(item.value)
                                                ? item.value.join(', ')
                                                : item.value}
                                            </Text>
                                          </Col>
                                          {nextEntry && (
                                            <Col span={11}>
                                              <Text strong>
                                                {transformationText(
                                                  nextEntry.key
                                                )}
                                              </Text>
                                              <Text type="secondary">
                                                {Array.isArray(nextEntry.value)
                                                  ? nextEntry.value.join(', ')
                                                  : nextEntry.value}
                                              </Text>
                                            </Col>
                                          )}
                                        </Row>
                                      );
                                    }
                                    return null;
                                  })}
                              </>
                              <Row style={{ marginTop: '20px' }} key={x.key}>
                                <Col flex="auto">
                                  <Text strong>
                                    {t(
                                      'care_guide_owners_cognito_field_comments'
                                    ) + ': '}
                                  </Text>
                                  <Text type="secondary">
                                    {x?.answers
                                      .filter((item) => item.key === 'Comments')
                                      .map((item) => {
                                        return item.value;
                                      })}
                                  </Text>
                                </Col>
                              </Row>
                              <Row style={{ marginTop: '20px' }} justify="end">
                                <Col>
                                  <Button
                                    style={{ borderRadius: '4px' }}
                                    type="primary"
                                    icon={<FileOutlined />}
                                  >
                                    {t(
                                      'care_guide_owners_cognito_download_pdf'
                                    )}
                                  </Button>
                                </Col>
                              </Row>
                            </Panel>
                          ) : null}
                        </>
                      ))
                    : null}
                </Collapse>
                <br />
                <div style={{ textAlign: 'center' }}>
                  <Pagination
                    defaultCurrent={5}
                    style={{ textAlign: 'center' }}
                    current={1}
                    pageSize={10}
                    showSizeChanger={true}
                    position={['bottomCenter']}
                    total={list.length * 10}
                    pageSizeOptions={['10', '20']}
                  />
                </div>
              </>
            </Col>
          </Row>
        </>
      )}
    </Translation>
  );
};

export default withRouter(DashboardAdminDailyLogs);
