import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Typography, Card, Row, DatePicker } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { Translation } from 'react-i18next';
import { partnership_form_answers } from '../../../mock/data';
import DashboardAdminResources from '../containers/DashboardAdminResourcesContainer';
import DashboardAdminDailyLogs from './DashboardAdminDailyLogs';
import DashboardAdminRecommendedUtilities from '../containers/DashboardAdminRecommendedUtilitiesContainer';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const DashboardAdminSections = ({
  load,
  patch,
  partnershipId,
  page,
  page_recommended_utilities,
  page_resources,
  filter,
  limit,
  order,
  component,
}) => {
  const [sections, setSections] = useState([]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    reorderedItem.order = result.destination.index + 1;
    items.splice(result.destination.index, 0, reorderedItem);

    setSections(items);

    patch(
      partnershipId,
      result.draggableId,
      { order: result.destination.index + 1 },
      (success) => {
        if (!success) {
          const itemsReverted = Array.from(sections);
          const [revertedItem] = itemsReverted.splice(
            result.destination.index,
            1
          );
          itemsReverted.splice(result.source.index, 0, revertedItem);
          setSections(itemsReverted);
        }
      }
    );
  };

  useEffect(() => {
    load(partnershipId, page, limit, order, (success, result) => {
      if (success) {
        setSections(result);
      }
    });
  }, [partnershipId, page, limit, order, load]);

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? '#E3F2FD' : '#FBFBFB',

    ...draggableStyle,
  });

  return (
    <Translation>
      {(t) => (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="sections">
            {(provided) => (
              <div
                className="sections"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {sections.map(({ id, type }, index) => (
                  <Draggable key={id} draggableId={id} index={index}>
                    {(provided, snapshot) => (
                      <Card
                        bordered={false}
                        className="section-card"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div>
                          <div style={{ display: 'flex', gap: '6px' }}>
                            <div>
                              <FontAwesomeIcon icon={faGripVertical} />
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <Title level={4}>
                                {t('dashboard_section_type_' + type)}
                              </Title>
                              {type === 2 && (
                                <RangePicker
                                  size="large"
                                  showTime
                                  format="MM/DD/YYYY"
                                />
                              )}
                            </div>
                          </div>
                          <Row>
                            {type === 1 && (
                              <DashboardAdminResources
                                filter={filter}
                                page_resources={page_resources}
                                page_recommended_utilities={
                                  page_recommended_utilities
                                }
                                component={component}
                              />
                            )}
                            {type === 2 && (
                              <DashboardAdminDailyLogs
                                list={partnership_form_answers}
                              />
                            )}
                            {type === 3 && (
                              <DashboardAdminRecommendedUtilities
                                page_recommended_utilities={
                                  page_recommended_utilities
                                }
                                page_resources={page_resources}
                                component={component}
                              />
                            )}
                          </Row>
                        </div>
                      </Card>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </Translation>
  );
};

export default DashboardAdminSections;
