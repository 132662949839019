import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import {
  hasPermissionAdmin,
  pathRedirect,
} from '../../../elements/lib/hasPermissionAdmin';
import { getRouteFromPath } from '../../../Routes';
import { Col, Row } from 'antd';
import { pathTo } from '../../../Routes';
import { Redirect } from 'react-router-dom';
import DocumentHead from '../../../elements/components/DocumentHead';
import { calendar_events, care_members } from '../../../mock/data';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';
import DashboardAdminHeaderScreen from './DashboardAdminHeaderScreen';
import DashboardAdminUpcomingEvents from './DashboardAdminUpcomingEvents';
import DashboardAdminCareMembers from './DashboardAdminCareMembers';
import DashboardAdminSections from '../containers/DashboardAdminSectionsContainer';
import QueryString from '../../../../../lib/QueryString';

class DashboardAdminScreen extends Component {
  static defaultProps = {
    limit: Config.get('DEFAULT_LIST_LENGTH', 10),
    isLoading: true,
    component: 'DashboardAdminScreen',
  };

  state = {
    redirect: null,
  };

  scrollToTop = () => {
    Logger.log('debug', `DashboardAdminScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  };

  render() {
    const page_recommended_utilities = parseInt(
      this.props.match.params.page_recommended_utilities
        ? this.props.match.params.page_recommended_utilities
        : 1
    );
    const page_resources = parseInt(
      this.props.match.params.page_resources
        ? this.props.match.params.page_resources
        : 1
    );
    const { ...filter } = QueryString.parse(this.props.location.search);

    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={pathTo(redirect)} />;
    }

    return (
      <Translation>
        {(t) => (
          <div>
            <DocumentHead title={t('dashboard_admin_route_list')} />
            <DashboardAdminHeaderScreen />
            <Row>
              <Col span={24}>
                <Row gutter={16}>
                  <Col xs={24} lg={18}>
                    <DashboardAdminSections
                      filter={filter}
                      component={this.props.component}
                      page_recommended_utilities={page_recommended_utilities}
                      page_resources={page_resources}
                    />
                  </Col>
                  <Col xs={24} lg={6}>
                    <DashboardAdminUpcomingEvents list={calendar_events} />
                    <DashboardAdminCareMembers list={care_members} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
  }

  componentDidUpdate(prevProps) {
    Logger.log('silly', `PartnershipAccountScreen.componentDidUpdate()`);
    const route = getRouteFromPath(this.props.location.pathname);
    const screen = hasPermissionAdmin();
    if (
      this.props.activeId !== prevProps.activeId &&
      route.screen !== pathRedirect(screen)[0]
    ) {
      this.setState({ redirect: pathRedirect(screen)[0] });
    }
  }
}

export default DashboardAdminScreen;

Logger.log('silly', `DashboardAdminScreen loaded.`);
