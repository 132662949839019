import React from 'react';
import { Button, Typography, Affix, Card, List, Row, Col, Space } from 'antd';
import { Translation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import UserAvatar from '../../../elements/components/UserAvatar';
import { TelephoneDisplayCard } from '../../../elements/components/TelephoneDisplay';
import { MessageLinkCard } from '../../messages/components/MessageLink';

const { Title, Text } = Typography;

const DashboardAdminCareMembers = ({ list, ...props }) => {
  return (
    <Translation>
      {(t) => (
        <Affix offsetTop={10}>
          <Card bordered={false} className="care-members-card">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Title level={4}>{t('dashboard_admin_care_members_title')}</Title>
              <Button type="primary" icon={<PlusOutlined />}>
                {t('dashboard_admin_care_members_btn_invite')}
              </Button>
            </div>
            <List
              itemLayout="horizontal"
              size="small"
              bordered={false}
              dataSource={list}
              renderItem={(item) => (
                <div className={`care-member-item`}>
                  <Row>
                    <Col style={{ marginRight: '10px' }}>
                      <UserAvatar profile={item.profile} size={30} />
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <Space direction="horizontal">
                            <Title level={5} style={{ display: 'inline' }}>
                              {item.profile
                                ? item.profile.first_name +
                                  ' ' +
                                  item.profile.last_name
                                : ''}
                            </Title>
                            <Text
                              style={{ fontSize: '16px', color: '#676767' }}
                            >
                              -
                            </Text>
                            <Text
                              style={{ fontSize: '16px', color: '#676767' }}
                            >
                              {item.is_owner
                                ? t('care_guide_helpers_role_owner')
                                : t('care_guide_helpers_role_' + item.role)}
                            </Text>
                          </Space>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '10px' }}>
                        <Col style={{ marginRight: '10px' }}>
                          <TelephoneDisplayCard
                            telephone={'877-881-094'}
                            onClick={(e) => {}}
                          />
                        </Col>
                        <Col>
                          <MessageLinkCard
                            profile={item.profile}
                            careGuideId={props.careGuideId}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}
            />
          </Card>
        </Affix>
      )}
    </Translation>
  );
};

export default DashboardAdminCareMembers;
