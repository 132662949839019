const calendar_events = [
  {
    amount_assigned_care_guides: 1,
    care_guide_id: 'd0b15016-3b58-45bb-90d0-c2aa11eb2f2e',
    description: '',
    end_at: null,
    id: '1d7e3165-8c2f-4ca7-9aca-5b6852dc2ee9',
    is_all_day: true,
    parent_event: null,
    participants: [
      {
        id: 'a5fac04d-839a-4be8-8bd0-0e70d053d70d',
        profile: {
          first_name: 'Jenson',
          id: 'ed129b3f-0e68-4e1d-a292-3d8647185066',
          last_name: 'Haley',
        },
        public_status: 2,
      },
    ],
    partnership: null,
    partnership_id: null,
    profile: {
      first_name: 'Lynne',
      id: '859673d2-2968-4fc1-b6f1-c6567d2263ac',
      last_name: 'Harford',
    },
    recurring: 1,
    recurring_end_at: null,
    start_at: '2024-09-24T00:00:00-0700',
    title: 'Medical supplies',
    url_event_partner: null,
    url_label: null,
  },
  {
    amount_assigned_care_guides: 1,
    care_guide_id: 'd0b15016-3b58-45bb-90d0-c2aa11eb2f2e',
    description: '',
    end_at: '2024-09-25T09:00:00-0700',
    id: '027ec8b6-f17c-47cd-a90a-5c42fd03a064',
    is_all_day: false,
    parent_event: null,
    participants: [
      {
        id: '2d0149b0-e169-4479-962e-5b3a329d1a64',
        profile: {
          first_name: 'Maitland',
          id: 'e1815692-70bd-48b8-8e06-32546cd701d3',
          last_name: 'Kadence',
        },
        public_status: 2,
      },
      {
        id: '66527838-133d-4876-8307-290d7c1fdc5c',
        profile: {
          first_name: 'Ronin',
          id: '79b03f41-489f-4941-b2cb-0700ce6d83c9',
          last_name: 'Jem',
        },
        public_status: 2,
      },
    ],
    partnership: null,
    partnership_id: null,
    profile: {
      first_name: 'Lynne',
      id: '859673d2-2968-4fc1-b6f1-c6567d2263ac',
      last_name: 'Harford',
    },
    recurring: 1,
    recurring_end_at: null,
    start_at: '2024-09-25T08:00:00-0700',
    title: 'Medical supplies',
    url_event_partner: null,
    url_label: null,
  },
  {
    amount_assigned_care_guides: 3,
    description: '',
    end_at: '2024-09-25T09:00:00-0700',
    id: 'b9b43560-f4af-4ebc-96ea-2023526d0817',
    is_all_day: false,
    parent_event: null,
    care_guide_id: null,
    participants: [
      {
        id: '00aa4c60-f94b-46a9-97db-40f320cb2d5b',
        profile: {
          first_name: 'Katlyn',
          id: '46d5f3bd-d8ba-4ea1-b7ff-b9d26ccbbdfb',
          last_name: 'Willis',
        },
        public_status: 2,
      },
    ],
    partnership: {
      name: 'Share The Care',
    },
    partnership_id: 'a364e142-d0e7-4b8f-ba73-5d83e77618c6',
    profile: {
      first_name: 'Lynne',
      id: '859673d2-2968-4fc1-b6f1-c6567d2263ac',
      last_name: 'Harford',
    },
    recurring: 1,
    recurring_end_at: null,
    start_at: '2024-09-25T08:00:00-0700',
    title: 'Medical supplies',
    url_event_partner: null,
    url_label: null,
  },
];

const care_members = [
  {
    id: '640e6d7f-9142-4fc7-a882-3eaad68d4ac1',
    invitation: null,
    is_owner: true,
    profile: {
      avatar: null,
      email: 'user2@test.com',
      first_name: 'Lynne',
      id: '61795ee9-7d51-49c9-8c86-7385e7da5e63',
      joined_at: '2018-12-07T00:00:00+0000',
      last_name: 'Harford',
      telephone: '5551239876',
    },
  },
  {
    care_guide_id: 'd0b15016-3b58-45bb-90d0-c2aa11eb2f2e',
    id: '351586a6-a482-448f-b9c4-820eb1524f2f',
    invitation: null,
    is_owner: false,
    joined_at: '2020-03-01T11:30:00+0000',
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    profile: {
      avatar: null,
      email: 'user3@test.com',
      first_name: 'Duane',
      id: 'da126dce-cfb1-4a50-b368-13c7caca3ccb',
      joined_at: '2018-12-12T00:00:00+0000',
      last_name: 'Hargrave',
      telephone: '',
    },
    public_status: 1,
    role: 3,
    tags: ['duis auctor', 'efficitur'],
  },
  {
    care_guide_id: 'd0b15016-3b58-45bb-90d0-c2aa11eb2f2e',
    id: 'd530077f-902b-4e1d-843f-eb6ced7ccce8',
    invitation: null,
    is_owner: false,
    joined_at: '2020-03-05T11:30:00+0000',
    note: '',
    profile: {
      avatar: null,
      email: 'user8@test.com',
      first_name: 'Luke',
      id: '690e2dfd-c6ea-46bc-9513-43c1c3a440a2',
      joined_at: '2019-01-07T00:00:00+0000',
      last_name: 'Tennyson',
      telephone: '5552349876',
    },
    public_status: 1,
    role: 2,
    tags: ['efficitur'],
  },
];

const partnership_form_answers = [
  {
    key: '24dcd048-1afb-4bca-b56d-4cd45205f395',
    answers: [
      {
        key: 'Date',
        value: '2024-09-25',
      },
      {
        key: 'Name',
        value: 'Noah Jones',
      },
      {
        key: 'Meals',
        value: [],
      },
      {
        key: 'MealsNote',
        value: 'Testing',
      },
      {
        key: 'Medications',
        value: 'Testing',
      },
      {
        key: 'RestroomVisits',
        value: ['Void', 'BM'],
      },
      {
        key: 'RestroomVisitNotes',
        value: 'Testing',
      },
      {
        key: 'BloodPressure',
        value: '1',
      },
      {
        key: 'Pulse',
        value: '1',
      },
      {
        key: 'Temperature',
        value: '1',
      },
      {
        key: 'ItemsNeeded',
        value: [],
      },
      {
        key: 'Activities',
        value: [
          'Chair Exercise',
          'Arts & Crafts',
          'Favoritie Games',
          'Current Events',
          'Sensory Stimuli',
          'Education',
          'Spirituality',
          'Music',
          'Dance',
          'Ball Toss/Kickball',
          'Massage Therapy',
          'Pet Therapy',
        ],
      },
      {
        key: 'Comments',
        value: 'Test',
      },
    ],
    created_at: '2024-09-25T23:16:34+0000',
    id: '24dcd048-1afb-4bca-b56d-4cd45205f395',
  },
  {
    key: '6e0eff6b-c088-4c01-a73c-abca0508d737',
    answers: [
      {
        key: 'Date',
        value: '2024-09-25',
      },
      {
        key: 'Name',
        value: 'Noah Jones',
      },
      {
        key: 'Meals',
        value: [],
      },
      {
        key: 'MealsNote',
        value: 'Testing',
      },
      {
        key: 'Medications',
        value: 'Testing',
      },
      {
        key: 'RestroomVisits',
        value: ['Void', 'BM'],
      },
      {
        key: 'RestroomVisitNotes',
        value: 'Testing',
      },
      {
        key: 'BloodPressure',
        value: '1',
      },
      {
        key: 'Pulse',
        value: '1',
      },
      {
        key: 'Temperature',
        value: '1',
      },
      {
        key: 'ItemsNeeded',
        value: [],
      },
      {
        key: 'Activities',
        value: [
          'Chair Exercise',
          'Arts & Crafts',
          'Favoritie Games',
          'Current Events',
          'Sensory Stimuli',
          'Education',
          'Spirituality',
          'Music',
          'Dance',
          'Ball Toss/Kickball',
          'Massage Therapy',
          'Pet Therapy',
        ],
      },
      {
        key: 'Comments',
        value: 'Test',
      },
    ],
    created_at: '2024-09-25T23:16:34+0000',
    id: '6e0eff6b-c088-4c01-a73c-abca0508d737',
  },
  {
    key: 'b164d4a6-5adc-4662-8199-b659527a8f43',
    answers: [
      {
        key: 'Date',
        value: '2024-09-25',
      },
      {
        key: 'Name',
        value: 'Noah Jones',
      },
      {
        key: 'Meals',
        value: [],
      },
      {
        key: 'MealsNote',
        value: 'Testing',
      },
      {
        key: 'Medications',
        value: 'Testing',
      },
      {
        key: 'RestroomVisits',
        value: ['Void', 'BM'],
      },
      {
        key: 'RestroomVisitNotes',
        value: 'Testing',
      },
      {
        key: 'BloodPressure',
        value: '1',
      },
      {
        key: 'Pulse',
        value: '1',
      },
      {
        key: 'Temperature',
        value: '1',
      },
      {
        key: 'ItemsNeeded',
        value: [],
      },
      {
        key: 'Activities',
        value: [
          'Chair Exercise',
          'Arts & Crafts',
          'Favoritie Games',
          'Current Events',
          'Sensory Stimuli',
          'Education',
          'Spirituality',
          'Music',
          'Dance',
          'Ball Toss/Kickball',
          'Massage Therapy',
          'Pet Therapy',
        ],
      },
      {
        key: 'Comments',
        value: 'Test',
      },
    ],
    created_at: '2024-09-25T23:16:34+0000',
    id: 'b164d4a6-5adc-4662-8199-b659527a8f43',
  },
  {
    key: '9f7a8684-67da-4dd3-b3f3-80d78bbc5d46',
    answers: [
      {
        key: 'Date',
        value: '2024-09-25',
      },
      {
        key: 'Name',
        value: 'Noah Jones',
      },
      {
        key: 'Meals',
        value: [],
      },
      {
        key: 'MealsNote',
        value: 'Testing',
      },
      {
        key: 'Medications',
        value: 'Testing',
      },
      {
        key: 'RestroomVisits',
        value: ['Void', 'BM'],
      },
      {
        key: 'RestroomVisitNotes',
        value: 'Testing',
      },
      {
        key: 'BloodPressure',
        value: '1',
      },
      {
        key: 'Pulse',
        value: '1',
      },
      {
        key: 'Temperature',
        value: '1',
      },
      {
        key: 'ItemsNeeded',
        value: [],
      },
      {
        key: 'Activities',
        value: [
          'Chair Exercise',
          'Arts & Crafts',
          'Favoritie Games',
          'Current Events',
          'Sensory Stimuli',
          'Education',
          'Spirituality',
          'Music',
          'Dance',
          'Ball Toss/Kickball',
          'Massage Therapy',
          'Pet Therapy',
        ],
      },
      {
        key: 'Comments',
        value: 'Test',
      },
    ],
    created_at: '2024-09-25T23:16:34+0000',
    id: '9f7a8684-67da-4dd3-b3f3-80d78bbc5d46',
  },
  {
    key: '5248a050-688e-4bfa-abc5-ec78ff8f1560',
    answers: [
      {
        key: 'Date',
        value: '2024-09-25',
      },
      {
        key: 'Name',
        value: 'Noah Jones',
      },
      {
        key: 'Meals',
        value: [],
      },
      {
        key: 'MealsNote',
        value: 'Testing',
      },
      {
        key: 'Medications',
        value: 'Testing',
      },
      {
        key: 'RestroomVisits',
        value: ['Void', 'BM'],
      },
      {
        key: 'RestroomVisitNotes',
        value: 'Testing',
      },
      {
        key: 'BloodPressure',
        value: '1',
      },
      {
        key: 'Pulse',
        value: '1',
      },
      {
        key: 'Temperature',
        value: '1',
      },
      {
        key: 'ItemsNeeded',
        value: [],
      },
      {
        key: 'Activities',
        value: [
          'Chair Exercise',
          'Arts & Crafts',
          'Favoritie Games',
          'Current Events',
          'Sensory Stimuli',
          'Education',
          'Spirituality',
          'Music',
          'Dance',
          'Ball Toss/Kickball',
          'Massage Therapy',
          'Pet Therapy',
        ],
      },
      {
        key: 'Comments',
        value: 'Test',
      },
    ],
    created_at: '2024-09-25T23:16:34+0000',
    id: '5248a050-688e-4bfa-abc5-ec78ff8f1560',
  },
];

export { care_members, calendar_events, partnership_form_answers };
